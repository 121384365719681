import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

const NotFound = () => {
    return (
        <div>
            <Navigation />
            <div className="content">
            <section className='section'>
                <p className='title'>Erreur 404</p>
                <p className='subtitle'>OUPS!</p>
            </section>
            </div>
            <Footer />
        </div>
    );
};

export default NotFound;