import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../assets/title-background2.png"

const Navigation = () => {

  function toggleBurgerMenu() {
    document.querySelector('.navbar-menu').classList.toggle('is-active');
    document.querySelector('.navbar-burger').classList.toggle('is-active');
  };

  return (
    <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main dropdown navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <img src={logo} alt="logo" />
          <h1 className='title is-4'>Epicerie des gentianes</h1>
        </div>

        <div role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu" onClick={toggleBurgerMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navMenu" className="navbar-menu">
        <div className="navbar-start">
          <NavLink to="/" className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')} onClick={toggleBurgerMenu}>Accueil</NavLink>
        </div>
        <div className="navbar-end">
          <NavLink to="/contact" className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')} onClick={toggleBurgerMenu}>Contacts</NavLink>
        </div>
      </div>
    </nav>

  );
};

export default Navigation;

