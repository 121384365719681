import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import mapShop from '../assets/mapShop.png';
import phoneNumb from '../assets/phonenumb.png';
import mailAdress from '../assets/mailAdress.png';

const Contact = () => {
    return (
        <div className="contact">
            <Navigation />
            <div className=''>
                <div className="contact-content tile is-ancestor">
                    <section className='section tile is-child'>
                        <h1 className='title'>Nous contacter</h1>
                        <h2 className='subtitle'>Besoin d'informations ou pour passer une commande?</h2>
                        <p>passez nous un coup de fil !</p>
                        <figure className='image phonenumb'>
                            <img src={phoneNumb} alt='numéro de téléphone'></img>
                        </figure>
                        <figure className='image phonenumb'>
                            <img src={mailAdress} alt='adresse email'></img>
                        </figure>
                    </section>
                    <section className='section tile is-child'>
                        <h2 className='title is-medium'>les horaires</h2>
                        <p>Ouverture tous les jours en saison estivale</p>
                        <p><strong>7h30 à 13h et de 16h à 19h</strong></p>
                        <p>En saison hivernal</p>
                        <p><strong>7h30 - 19h</strong></p>
                    </section>
                    <section className='section tile is-child'>
                        <h2 className='title is-small'>Nous sommes ici !</h2>
                        <p>Le chamois d'or</p>
                        <p>38580 Le Collet d'Allevard</p>
                        <figure className='image mapshop'>
                            <img src={mapShop} alt='carte de la boutique'></img>
                        </figure>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;