import React from 'react';
import Modal from './Modal';

const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <div className="footer hero-foot">
            <div>
                <p>Epicerie des gentianes @{year}</p>
            </div>
            <div>
                <Modal/>
            </div>
        </div>
    )
};

export default Footer;