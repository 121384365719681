import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';

const Login = () => {

    let initialState = {
        login: '',
        password: '',
        isLogged: false,
        title: '',
        description: '',
        price: '',
        status: '',
        id: '',
        refreshN: 0,
        initArray: [],
    };


    const [login, setlogin] = useState(initialState.login);
    const [password, setpassword] = useState(initialState.password);
    const [isLogged, setLogged] = useState(initialState.isLogged);

    const [title, setTitle] = useState(initialState.title);
    const [description, setDescription] = useState(initialState.description);
    const [price, setPrice] = useState(initialState.price);

    const [status, setStatus] = useState(initialState.status);

    const [data, setData] = useState(initialState.initArray);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(initialState.refreshN);
    let none = 0;
    // console.log(refresh);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get("https://epicerie-des-gentianes.fr/api/platsdujour");
                setData(result.data);
            } catch (error) {
                console.error(error)
                setStatus("base de donnée vide");
            }
            setLoading(false);
        };
        fetchData();
    }, [none, refresh]);

    function handleSubmit(e) {
        e.preventDefault();
        axios.post(('https://epicerie-des-gentianes.fr/api/admin-login'), {
            auth: {
                username: login,
                password: password,
            }
        }, {
            auth: {
                username: login,
                password: password,
            }
        }).then(function () {
            // console.log('Authenticated');
            setLogged(true);

        }).catch(function (error) {
            console.log('Errorrrr');
        });
    };

    function handleCreate(e) {
        e.preventDefault();
        axios.post(('https://epicerie-des-gentianes.fr/api/new-plat'), {
            food: {
                title: title,
                description: description,
                price: price,
                isLogged: isLogged,
            }
        }).then(function () {
            setTitle('');
            setDescription('');
            setPrice('');
            setStatus('Nouveau produit rajouté !');
            setRefresh(Math.random());

        }).catch(function (error) {
            // console.log(error.data.data);
            setStatus(error.data.data);
        });
    };

    function handleSelectFood(e) {
        axios.post(('https://epicerie-des-gentianes.fr/api/plat-delete'), {
            food: {
                isLogged: isLogged,
                _id: e.target.value,
            }
        }).then(function () {
            // console.log('produit suprimé');
            setStatus('produit supprimé');
            setRefresh(Math.random());
        }).catch(function (error) {
            // console.log(error.data.data);
            setStatus(error.data.data);
            // console.log('Error on delete');
        });
    }


    function handleChangeProductTitle(e) {
        setTitle(e.target.value);
    };

    function handleChangeProductDesc(e) {
        setDescription(e.target.value);
    };

    function handleChangeProductPrice(e) {
        setPrice(e.target.value);
    };

    function handleChangeLogin(e) {
        setlogin(e.target.value);
    };

    function handleChangePass(e) {
        setpassword(e.target.value);
    };

    if (isLogged === false) {
        return (
            <div>
                <section>
                    <div className='allprods'>
                        {loading && <div>Chargement des produits</div>}
                        {!loading && (
                            <div className='section is-flex is-flex-wrap-wrap is-justify-content-center'>
                                {data.map(item => (
                                    <article className='daily-food-tiles' key={item._id}>
                                        <div className='content box content-tiles'>
                                            <p className='title'>{item.titre}</p>
                                            <p className='subtitle'>{item.description}</p>
                                            <p>{item.prix} euros </p>
                                        </div>
                                    </article>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
                <section className='section login-panel box'>
                    <div className='field'>
                        <label className='label'>Nom d'utilisateur</label>
                        <div className='control has-icons-left'>
                            <input className='input' type='text' name='login' placeholder="Nom d'utilisateur" value={login} onChange={handleChangeLogin}></input>
                            <span className='icon is-small is-left'>
                                <i className='fas fa-user'></i>
                            </span>
                        </div>
                    </div>
                    <div className='field'>
                        <form>
                            <label className='label'>Mot de passe</label>
                            <div className='control has-icons-left'>
                                <input className='input' type='password' name='password' autoComplete='current-password' placeholder="Mot de passe" value={password} onChange={handleChangePass}></input>
                                <span className='icon is-small is-left'>
                                    <i className='fas fa-user'></i>
                                </span>
                                <span>
                                    <input type="submit" className="button is-link" value="valider" onKeyUp={handleSubmit} onClick={handleSubmit}></input>
                                </span>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        );
    }

    if (isLogged === true) {
        return (
            <div className='admin-panel-mod'>
                <section>
                    <div className='subtitle querry-status'>{status}</div>
                </section>
                <div className='allprods'>
                    {loading && <div>Chargement des produits</div>}
                    {!loading && (
                        <div className='section is-flex is-flex-wrap-wrap is-justify-content-center'>
                            {data.map(item => (
                                <article className='daily-food-tiles' key={item._id}>
                                    <div className='content box content-tiles'>
                                        <p className='title'>{item.titre}</p>
                                        <p className='subtitle'>{item.description}</p>
                                        <p>{item.prix} euros </p>
                                        <button type="button" value={item._id} onClick={handleSelectFood} className='delete-button'>supprimer</button>
                                    </div>
                                </article>
                            ))}
                        </div>
                    )}
                </div>
                <div className='createFood'>
                    <article className='column daily-food-tiles'>
                        <div className='content box content-tiles'>
                            <div>
                                <p className='subtitle'>Créer un nouveau produit !</p>
                            </div>
                            <form>
                                <div>
                                    <label className='label'>Titre du produit</label>
                                    <input className='input' type='text' name='titre' placeholder="Titre du produit" value={title} onChange={handleChangeProductTitle}></input>
                                </div>
                                <div>
                                    <label className='label'>Description du produit</label>
                                    <input className='input' type='text' name='description' placeholder="Titre du produit" value={description} onChange={handleChangeProductDesc}></input>
                                </div>
                                <div>
                                    <label className='label'>Prix(uniquement des chiffres)</label>
                                    <input className='input' type='number' name='prix' placeholder="Titre du produit" value={price} onChange={handleChangeProductPrice}></input>
                                </div>
                                <div>
                                    <input type='submit' onClick={handleCreate} value="valider"></input>
                                </div>
                            </form>
                            <p className='querry-status'>{status}</p>
                        </div>
                    </article>
                </div>
            </div>
        )
    }
}

export default Login;
