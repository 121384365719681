import React from 'react';
import { useState } from 'react';
import phonumb from '../assets/phonenumb.png';
import mailadr from '../assets/mailAdress.png';

const Modal = () => {

    const [state, setState] = useState(false);
    const [show, setShow] = useState('');
    const mod = 'modal';

    const toggleActive = () => {
        setState(!state);
        if (state === true) {
            setShow(' is-active');
        }
        if (state === false) {
            setShow('');
        }
    }

    return (
        <div>
            <button type='button' onClick={toggleActive}>Mentions Légales</button>
            <div className={mod + show}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Mentions Légales</p>
                        <button className="delete" aria-label="close" onClick={toggleActive}></button>
                    </header>
                    <section className="modal-card-body">
                        <div>
                            <p><strong>
                                EPICERIE DES GENTIANES
                            </strong></p>
                            <p>
                                immeuble le Chamois d'Or
                            </p>
                            <p>
                                38580 COLLET D'ALLEVARD
                            </p>
                            <div>
                                <img alt='mail' src={mailadr}></img>
                                <img alt='phone number' src={phonumb}></img>
                            </div>
                            <p>
                                RCS 892 611 682 Grenoble
                            </p>
                        </div>
                        <div>
                            <p><strong>
                                Hébergeur du site
                            </strong></p>
                            <p>Gandi SAS au capital de 800 000 € ayant son siège social au 63-65 boulevard Massena à Paris (75013), immatriculée sous le numéro 423 093 459 au RCS de Paris.
                            </p>
                        </div>
                        <div>
                            <p><strong>
                                politique de traitement de données
                            </strong></p>
                            <p>nous récoltons aucune données relative aux utilisateurs et visiteurs, ce site est une simple vitrine de l'épicerie.</p>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" onClick={toggleActive}>fermer</button>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default Modal;