import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import '../src/styles/index.scss';
import Admin from './pages/Admin';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={ <Home />} />
          <Route exact path="/contact" element={ <Contact />} />
          <Route exact path="/admin" element={ <Admin />} />
          <Route path="/*" element={<NotFound/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
