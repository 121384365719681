import React from 'react';

const NavHome = () => {
    return (
        <div className='navbar navhome is-fixed-bottom'>
            <ul className='menu-list'>

                <li>
                    <a href='#top'>
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M4.08,11.92L12,4L19.92,11.92L18.5,13.33L13,7.83V22H11V7.83L5.5,13.33L4.08,11.92M12,4H22V2H2V4H12Z" />
                        </svg>
                    </a>
                </li>

                <li>
                    <a href='#epicerie'>
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
                        </svg>
                    </a>
                </li>

                <li>
                    <a href='#dailyprod'>
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18.06 23H19.72C20.56 23 21.25 22.35 21.35 21.53L23 5.05H18V1H16.03V5.05H11.06L11.36 7.39C13.07 7.86 14.67 8.71 15.63 9.65C17.07 11.07 18.06 12.54 18.06 14.94V23M1 22V21H16.03V22C16.03 22.54 15.58 23 15 23H2C1.45 23 1 22.54 1 22M16.03 15C16.03 7 1 7 1 15H16.03M1 17H16V19H1V17Z" />
                        </svg>
                    </a>
                </li>

                <li>
                    <a href='#gallery'>
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
                        </svg>
                    </a>
                </li>

                <li>
                    <a href='#bottom'>
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19.92,12.08L12,20L4.08,12.08L5.5,10.67L11,16.17V2H13V16.17L18.5,10.66L19.92,12.08M12,20H2V22H22V20H12Z" />
                        </svg>
                    </a>
                </li>

            </ul>
        </div>
    );
};

export default NavHome;