import React from 'react';
import Login from '../components/Login';
import Navigation from '../components/Navigation';



const Admin = () => {

    return (
        <div className="admin-page-login">
            <Navigation />
            <section className='hero is-primary is-small'>
                <div className='hero-body'>
                    <h2 className='title'>Page d'administration</h2>
                </div>
            </section>
            <section className='section'>
                <Login />
            </section>
        </div>
    );
};

export default Admin;