import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import colletPano from '../assets/front-store.png';
import { NavLink } from 'react-router-dom';
import Gallery from '../components/Gallery';
import NavHome from '../components/NavHome';


const Home = () => {

    return (
        <div id="top" className="home bckgrnd">
            <Navigation />
            <NavHome />
            <section className="hero is-bold box">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div>
                            <h1 className="title">Epicerie des Gentianes</h1>
                            <h2 className="subtitle">
                                Le Collet d'Allevard
                            </h2>
                        </div>
                        <div className='title-content'>
                            <p>Ouverture tous les jours en saison estivale</p>
                            <p><strong>7h30 à 13h et de 16h à 19h</strong></p>
                            <p>En saison hivernale</p>
                            <p><strong>7h30 - 19h</strong></p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <section className="articles">
                    <div className="column is-8 is-offset-2 box">
                        <div className="card article box">
                            <div className="card-content">
                                <div id="epicerie" className="media">
                                    <div className="media-content has-text-centered">
                                        <p className="title article-title">L'épicerie</p>
                                        <figure className='image is-inline-block'>
                                            <img className='img' alt='collet' src={colletPano} />
                                            <p className='img-desc-name'>© Julia H.P</p>
                                        </figure>
                                    </div>
                                </div>
                                <div className="content article-body has-text-centered">
                                    <p>Vous trouverez pains - viennoiseries et produits locaux :</p>
                                    <p><strong>Fromages, miels, confitures ...</strong></p>
                                    <p>Ansi que des plateaux de coquillages, (suivant arrivage)</p>
                                    <p>et des produits festifs : </p>
                                    <p><strong>Foie gras, caviar, saumon aneth ...</strong></p>
                                    <p>Nous proposons aussi des plats du jour fait maison à déguster sur place ou à emporter</p>
                                    <p><strong>Venez également tester notre bar à fondue</strong></p>

                                    <h2><NavLink to="/contact" className='contact-home'>Contacts et Commandes</NavLink></h2>
                                </div>
                            </div>
                        </div>

                        <div id='dailyprod' className="card article box">
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content has-text-centered">
                                        <p className="title article-title">Nos produits du jour</p>
                                    </div>
                                </div>
                                <div className="content article-body has-text-centered">
                                    
                                </div>
                            </div>
                        </div>

                        <div id='gallery' className="card article box">
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content has-text-centered">
                                        <p className="title article-title">La gallerie</p>
                                    </div>
                                </div>
                                <div className="content article-body has-text-centered">
                                    <Gallery />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id='bottom'>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
