import React from 'react';
import boissons from '../assets/liqueur.jpg';
import confitures from '../assets/confitures.jpg';
import patisserie from '../assets/gateau.jpg';
import fromages from '../assets/fromages1.jpg';
import fromages2 from '../assets/fromages2.jpg';
import charcuterie from '../assets/charcuterie.jpg';
import viandes from '../assets/viandes.jpg';
import vitrine from '../assets/vitrine.jpg';
import cloche from '../assets/cloche.jpg';
import poissons from '../assets/poissons.jpg';
import baguettes from '../assets/baguettes.jpg';
import saumon from '../assets/saumon.jpg';


const Gallery = () => {
    return (
        <div className='gallery'>

            <figure className="is-inline-block image">
                <img className="img" src={boissons} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={confitures} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={patisserie} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={fromages} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={fromages2} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={charcuterie} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={viandes} alt='' />
            </figure>

            <figure className="is-inline-block image">
                <img className="img" src={vitrine} alt='' />
            </figure>

            <figure className="is-inline-block   image">
                <img className="img" src={cloche} alt='' />
            </figure>

            <figure className="is-inline-block   image">
                <img className="img" src={saumon} alt='' />
            </figure>

            <figure className="is-inline-block   image">
                <img className="img" src={poissons} alt='' />
            </figure>

            <figure className="is-inline-block   image">
                <img className="img" src={baguettes} alt='' />
            </figure>

        </div>
    );
};

export default Gallery;